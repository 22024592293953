import React from 'react';
import './AssignmentsTable.css';
import {Technician} from "../types";

const formatDate = (s: string): string => {
    if (s === '') {
        return '';
    }
    const date = new Date(s);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const formatTime = (s: string): string => {
    if (s === '') {
        return '';
    }
    const date = new Date(s);
    const hours = date.getHours();
    const minutes = padWithZero(date.getMinutes());
    return `${hours}:${minutes}`;
};

const formatDateAndTime = (s: string): string => {
    if (s === '') {
        return '';
    }
    return formatDate(s) + ' ' + formatTime(s)
}

const padWithZero = (number: number): string => {
    return number < 10 ? '0' + number : '' + number;
};

function truncateText(text: string | undefined, maxLength: number): string | undefined {
    if (!text || text?.length <= maxLength) {
        return text;
    }
    return text?.slice(0, maxLength - 3) + '...';
}

interface AssignmentsTableProps {
    data: Technician[];
}

const AssignmentsTable: React.FC<AssignmentsTableProps> = ({data}) => {
    const calculateDuration = (start: string, end: string): number => {
        const startTime = new Date(start).getTime();
        const endTime = new Date(end).getTime();
        return (endTime - startTime) / (1000 * 60); // duration in minutes
    };

    return (
        <div className="technician-assignments">
            <table>
                <thead>
                <tr>
                    {data.map((tech) => (
                        <th key={tech.id}>
                            <strong>{tech.name} ({tech.id})</strong>
                            <ul>
                                {tech.technicianSkills.map((t) => (
                                    <li>{t.skill.name} - {t.years} yrs
                                        / {t.timesUsed} times {t.certified ? ' / CERTIFIED' : ''}</li>
                                ))}
                            </ul>
                            <ul>
                                {tech.properties?.map((p) => (
                                    <li>Property: {p.id}</li>
                                ))}
                            </ul>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                <tr>
                    {data.map((tech) => (
                        <td key={tech.id}>
                            {tech.assignments?.map((assignment) => {
                                const duration = calculateDuration(assignment.start, assignment.end);
                                return (
                                    <div
                                        key={assignment.id}
                                        className="assignment-block"
                                        style={{height: `${duration}px`}}
                                        title={assignmentToolTip()}
                                    >
                                        <strong>{truncateText(assignment.workOrder.name, 50)}</strong>
                                        <ul>
                                            <li>Location: <strong>{assignment.workOrder.location.id}</strong> - Property: <strong>{assignment.workOrder.location.property?.id}</strong></li>
                                            <li>Sched: {formatDate(assignment.start)} {formatTime(assignment.start)} - {formatTime(assignment.end)}</li>
                                            <li>Priority: {assignment.workOrder.urgent ? '*URGENT*' : ''} {assignment.workOrder.raisedByResident ? '*SERVICE REQUEST*' : ''}</li>
                                            <li>Raised: {formatDateAndTime(assignment.workOrder.raisedAt || '')}</li>
                                            <li>Skills: {assignment.workOrder.requiredSkills.map((r) => r.skill.name + (r.requiresCertification ? ' *CERTIFICATION REQUIRED*' : '')).join(', ')}</li>
                                        </ul>
                                    </div>
                                );

                                function assignmentToolTip(): string | undefined {
                                    return assignment.workOrder.name
                                        + '\n* Location: ' + assignment.workOrder.location.id + ' - Property: ' + assignment.workOrder.location.property?.id
                                        + '\n* Sched: ' + formatDate(assignment.start) + ' ' + formatTime(assignment.start) + ' - ' + formatTime(assignment.end)
                                        + '\n* Priority: ' + (assignment.workOrder.urgent ? '*URGENT*' : '') + (assignment.workOrder.raisedByResident ? ' *SERVICE REQUEST*' : '')
                                        + '\n* Raised: ' + formatDateAndTime(assignment.workOrder.raisedAt || '')
                                        + '\n* Skills: ' + assignment.workOrder.requiredSkills.map((r) => r.skill.name + (r.requiresCertification ? ' *CERTIFICATION REQUIRED*' : '')).join(', ');
                                }
                            })}
                        </td>
                    ))}
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AssignmentsTable;

import React from 'react';
import './App.css';
import WoaEditor from './components/WoaEditor';

const App: React.FC = () => {
  return (
      <div className="App">
        <WoaEditor />
      </div>
  );
}

export default App;

import React from 'react';
import {AssignmentsData} from "../types";
import AssignmentsTable from "./AssignmentsTable";

const formatDateAndTime = (s: string): string => {
    if (s === '') {
        return '';
    }
    const date = new Date(s);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = padWithZero(date.getMinutes());
    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const formatTime = (s: string): string => {
    if (s === '') {
        return '';
    }
    const date = new Date(s);
    const hours = date.getHours();
    const minutes = padWithZero(date.getMinutes());
    return `${hours}:${minutes}`;
};

const padWithZero = (number: number): string => {
    return number < 10 ? '0' + number : '' + number;
};

interface AssignmentsProps {
    data: AssignmentsData;
}

const Assignments: React.FC<AssignmentsProps> = ({data}) => {
    return (
        <div>
            <h1>Smart Assign Results</h1>
            <p>Based on data: {data.name}</p>

            <AssignmentsTable data={data.technicians}/>

            <div className="results">
                <div className="score">
                    <h1>Score</h1>
                    <table>
                        <tbody>
                        {Object.entries(data.score).map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{value.toString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="config">
                    <h1>Config Used</h1>
                    <table>
                        <tbody>
                        {Object.entries(data.configuration)
                            .filter(([key, value]) => key !== 'defaultTechnicianWorkingHours')
                            .map(([key, value]) => (
                                <tr key={key}>
                                    <td>{key}</td>
                                    <td>{value.toString()}</td>
                                </tr>
                            ))}
                        <tr>
                            <td>defaultTechnicianWorkingHours</td>
                            <td>
                                <table>
                                    <tbody>
                                    {data.configuration.defaultTechnicianWorkingHours?.hours.map((hour, index) => (
                                        <tr key={index}>
                                            <td>{formatDateAndTime(hour.start)} - {formatTime(hour.end)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Assignments;
